import React from 'react';
import { graphql } from 'gatsby';
import FullWidthContainer from '../components/fullWidthContainer';
import JustifiedContainer from '../components/justifiedContainer';
import Layout from '../components/layout';
import { SliceZone } from '../sliceZone';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../utils/htmlSerializer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from '@reach/router';
import Image from '../components/image';

const BlogPostTemplate = ({ data }) => {
  if (!data) {
    return null;
  }

  const doc = data.prismicBlogPost;

  const { blog_post_content, blog_title, body } = doc.data;

  const companyInfoData = data.prismicCompanyLogo.data;

  const location = useLocation();

  const thisUrl = encodeURIComponent(location.href);

  const backgroundImage = doc.data.body[0].primary.image.gatsbyImageData;
  const backgroundImageFluidStack = [
    `linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.65), rgba(0,0,0,0.85))`,
    backgroundImage,
  ];

  const companyLogoImage = companyInfoData.logo.gatsbyImageData;
  const companyLogoAlt = companyInfoData.logo.alt;

  return (
    <Layout
      pageTitle={blog_title}
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoImage={companyLogoImage}
      companyLogoAlt={companyLogoAlt}
      logoClassName='max-w-xxxxs 2xl:max-w-xxxs 3xl:max-w-xxs'
      backgroundStyle={{ height: `50%` }}
      contentInsideBackground={() => (
        <FullWidthContainer className='flex flex-col'>
          <JustifiedContainer className='mb-8 text-center md:text-base'></JustifiedContainer>
        </FullWidthContainer>
      )}
    >
      <div className='flex w-full flex-col items-center px-8 md:px-0'>
        <SliceZone slices={body} />
        <div className='flex w-full flex-col items-center md:w-11/12'>
          <Image
            image={backgroundImage}
            alt='background image'
            className='h-96 rounded shadow md:mt-2'
          />
          <div className='w-full py-8 md:w-3/5 mega:w-3/5'>
            {/* <RichText render={blog_post_content.raw} htmlSerializer={htmlSerializer} /> */}
            <div className='relative overflow-hidden bg-white py-16'>
              <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
                <div
                  className='relative mx-auto h-full max-w-prose text-lg'
                  aria-hidden='true'
                >
                  <svg
                    className='absolute top-12 left-full translate-x-32 transform'
                    width={404}
                    height={384}
                    fill='none'
                    viewBox='0 0 404 384'
                  >
                    <defs>
                      <pattern
                        id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits='userSpaceOnUse'
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className='text-gray-200'
                          fill='currentColor'
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={384}
                      fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
                    />
                  </svg>
                  <svg
                    className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
                    width={404}
                    height={384}
                    fill='none'
                    viewBox='0 0 404 384'
                  >
                    <defs>
                      <pattern
                        id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits='userSpaceOnUse'
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className='text-gray-200'
                          fill='currentColor'
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={384}
                      fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
                    />
                  </svg>
                  <svg
                    className='absolute bottom-12 left-full translate-x-32 transform'
                    width={404}
                    height={384}
                    fill='none'
                    viewBox='0 0 404 384'
                  >
                    <defs>
                      <pattern
                        id='d3eb07ae-5182-43e6-857d-35c643af9034'
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits='userSpaceOnUse'
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className='text-gray-200'
                          fill='currentColor'
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={384}
                      fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
                    />
                  </svg>
                </div>
              </div>
              <div className='relative sm:px-4 lg:px-8'>
                <div className='mx-auto max-w-prose text-lg'>
                  <h1>
                    <span className='mt-2 block text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl'>
                      {blog_title}
                    </span>
                  </h1>
                  <div className='py-4 text-gray-600'>
                    <a
                      className='hover:text-gray-800'
                      href={`https://twitter.com/intent/tweet?url=${thisUrl}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size='1x'
                        className='mx-1'
                      />
                    </a>
                    <a
                      className='hover:text-gray-800'
                      href={`https://wwww.facebook.com/sharer/sharer.php?u=${thisUrl}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        size='1x'
                        className='mx-1'
                      />
                    </a>
                  </div>
                </div>
                <div className='prose-primary prose prose-lg mx-auto mt-6 text-gray-600'>
                  <RichText
                    render={blog_post_content.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostPageQuery($id: String) {
    prismicCompanyLogo {
      data {
        company_name
        logo {
          gatsbyImageData
          alt
        }
      }
    }
    prismicBlogPost(id: { eq: $id }) {
      uid
      id
      last_publication_date
      data {
        blog_title
        blog_post_content {
          raw
        }
        body {
          ... on PrismicBlogPostDataBodyBackgroundHeroImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPostTemplate;
